<template>
	<div class="GroupRegistration">
		<div class="container">
			<img src="@/views/images/Grouplogo.png" class="Grouplogo" />
			<div class="Downloadnow">
				专业技术人员集体报名表.xls
				<img src="@/views/images/Downloadnow.png" class="DownloadnowBtn" @click="DownloadnowBtn" />
			</div>
			<div class="panel">
				<img src="@/views/images/you.png" class="topyou" />
				<img src="@/views/images/you.png" class="bottomyou" />
				<div class="panelItem panelLeft">
					<div class="panelHeader">
						<img src="@/views/images/1.png" />
						{{info.col_first_title}}
					</div>
					<div v-html="info.col_first_step" class="panelCon"></div>
					<!-- <div class="panelText">（1）填写学员信息与所学课程；</div>
					<div class="panelText">（2）填写发票信息表。<span class="colorRed">（开发票时填写）</span></div> -->
				</div>
				<div class="panelItem panelRgiht">
					<div class="panelHeader">
						<img src="@/views/images/2.png" />
						{{info.col_two_title}}
					</div>
					<div v-html="info.col_two_step" class="panelCon"></div>
					<!-- <div class="panelText panelText3">邮箱：1191731870@qq.com</div>
					<div class="panelText panelText3">联系人：李老师</div>
					<div class="panelText panelText3">电话： 17302928373</div> -->
				</div>
				<div class="panelItem panelLeft nomarginb">
					<div class="panelHeader">
						<img src="@/views/images/3.png" />
						{{info.col_three_title}}
					</div>
					<div v-html="info.col_three_step" class="panelCon"></div>
					<!-- <div class="panelText">（1）请提前准备支付宝或者微信支付</div>
					<div class="panelText">（2）联系客服人员在线协助您完成报名缴费</div> -->
				</div>
				<div class="panelItem panelRgiht nomarginb">
					<div class="panelHeader">
						<img src="@/views/images/4.png" />
						{{info.col_four_title}}
					</div>
					<div v-html="info.col_four_step" class="panelCon"></div>
					<!-- <div class="panelText">（1）报名负责人收到学员登录信息；</div>
					<div class="panelText">（2）发送电子发票至负责人邮箱。</div> -->
				</div>
			</div>
		</div>
		<button class="button" type="button" @click="goHome">返回首页</button>
		<!-- <div class="footer">
			培训单位：专业技术人员继续教育基地
		</div> -->
	</div>
</template>

<script>
	export default {
		data() {
			return {
				info:{}
			}
		},
		methods: {
			//返回首页
			goHome(){
				this.$router.push({
					path: "/"
				});
			},
			//集体报名
			getgroupRegistration(){
				this.$http.post(this.PublicJs.publicPath + "/api/website/Index/groupRegistration.html", {
					emulateJSON: true
				}).then(response => {
					if(response.data.code == 1){
						this.info = response.data.data;
					}else{
						this.$message({
							message: response.data.msg,
							type: 'warning',
							showClose: true
						});
					}
				}, response => {
					console.log(response)
				});
			},
			//下载
			DownloadnowBtn(){
				if(this.info.col_template){
					window.location.href = this.info.col_template;
				}else{
					this.$message({
						message: "暂无附件，请联系客服",
						type: 'warning',
						showClose: true
					});
				}
			}
		},
		created(){
			//集体报名
			this.getgroupRegistration()
		}
	}
</script>

<style scoped="scoped">
	.GroupRegistration{
		width: 100%;
		height: 100%;
		background-image: url(images/GroupBg.png);
		background-repeat: no-repeat;
		background-size: cover;
		min-width: 1200px;
	}
	.button{
		width: 120px;
		height: 40px;
		border-radius: 20px;
		border:1px solid #fff;
		color: #fff;
		font-size:16px ;
		position: absolute;
		top: 840px;
		left: 50%;
		margin-left: -60px;
		z-index: 999;
		background: transparent;
		cursor: pointer;
	}
	.container{
		width: 1200px;
		height: 692px;
		position: absolute;
		top: 50%;
		left: 50%;
		margin-left: -600px;
		margin-top: -346px;
	}
	.Grouplogo{
		display: block;
		margin: 0 auto;
	}
	.Downloadnow{
		width: 685px;
		height: 60px;
		margin: 65px auto;
		background-color: #fff;
		border-radius: 6px;
		position: relative;
		line-height: 60px;
		padding-left: 59px;
		font-size: 26px;
		color: #3c81f9;
	}
	.DownloadnowBtn{
		position: absolute;
		right: 0;
		top: -3px;
		cursor: pointer;
	}
	.panel{
		position: relative;
	}
	.panel:after{
		content: "";
		display: block;
		clear: both;
	}
	.panelItem{
		width: 550px;
		height: 200px;
		background-color: #fff;
		border-radius: 20px;
		-moz-box-shadow:7px 7px 5px rgba(107,159,246,0.5); 
		-webkit-box-shadow:7px 7px 5px rgba(107,159,246,0.5); 
		box-shadow:7px 7px 5px rgba(107,159,246,0.5);
		margin-bottom: 52px;
	}
	.nomarginb{
		margin-bottom: 0;
	}
	.panelLeft{
		float: left;
	}
	.panelRgiht{
		float: right;
	}
	.topyou{
		position: absolute;
		top: 83px;
		left: 50%;
		margin-left: -26px;
	}
	.bottomyou{
		position: absolute;
		bottom: 62px;
		left: 50%;
		margin-left: -26px;
	}
	.panelHeader{
		padding-left: 9px;
		padding-top: 32px;
		line-height: 36px;
		width: 100%;
		font-size: 20px;
		font-weight: bold;
	}
	.panelHeader img{
		vertical-align: top;
		margin-right: 5px;
	}
	.panelCon{
		padding-left: 58px;
		padding-right: 10px;
		padding-top: 24px;
	}
	/* .panelText{
		font-size: 16px;
		padding-left: 58px;
		padding-right: 10px;
		padding-top: 24px;
	}
	.panelText3{
		padding-top: 15px;
	}
	.colorRed{
		color: #ff0000;
	} */
	.footer{
		color: #FFF;
		text-align: center;
		font-size: 16px;
		position: absolute;
		width: 100%;
		left: 0;
		bottom: 20px;
	}
</style>
